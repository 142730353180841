
/*
----------------------------------------------------------
DICE
----------------------------------------------------------
*/

main.dice {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 88vh;
}

.dice__inner { 
  width: 100%;
  height: 100% !important;
 }
