
.dice_splash {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.dice_splash__inner {
  width: calc(100% - 56px);
  margin: 30px 28px 0;
}

.dice_splash__inner .dice-top {
  margin-bottom: 3vh;
}

.dice_splash__inner .dice-bottom {
  text-align: right;
  margin-top: 3vh;
}

.dice_splash__inner h1 {
  font-size: 46px;
  line-height: 47px;
  font-weight: bold;
  text-align: left;
}

.dice_splash__inner p {
  text-align: left;
  margin-bottom: 30px;
}

.dice_splash__inner button.btn-cta {
  margin-top: 2vh;
  font-family: 'cubano', 'Helvetica', verdana, arial, sans-serif;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 12px;
  font-weight: 900;
  box-sizing: border-box;
  border: 4px solid #000;  
}

.lang-toggle,
.lang-toggle:hover,
.lang-toggle:visited { 
  position: absolute;
  top: 25px;
  right: 35px;
  font-size: 20px;
  text-decoration: none;
  font-family: 'cubano', 'Helvetica', verdana, arial, sans-serif;
}

@media screen and (height <= 740px) { 

  div.dice-top > img {
    width: 132px;
  }

  div.dice-bottom > img {
    width: 116px;
  }

  .dice_splash__inner h1 {
    font-size: 45px;
  }

  .dice_splash__inner p {
    margin-bottom: 18px;
  }

}

@media screen and (max-width: 360px) { 

  .dice_splash__inner h1 {
    font-size: 43px;
    line-height: 46px;
  }

}

@media screen and (max-width: 330px) { 

  .dice_splash__inner h1 {
    font-size: 37px;
    line-height: 38px;
  }

}