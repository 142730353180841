
/*
----------------------------------------------------------
COMPONENT: LOGO HEADER
----------------------------------------------------------
*/

.logo_footer {
  display: block;
}

.logo_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 12vh !important;
  overflow: hidden;
}

.logo_footer a {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo_footer img {
  max-width: 85%;
  max-height: 90%;
  margin  : 0 auto;
}

@media screen and (max-width: 768px) {

  .logo_footer a img {

  }
  
}

