
#dicebox,
#dicebox canvas {
  width: 100%;
  height: 100% !important;
  margin: 0 auto;
}

.dice_canvas,
.dice_canvas__inner { 
  width: 100%;
  height: 100%;

 }

@media screen and (width > 810px) {
  #dicebox {
    border: 1px solid black;
  }
}
