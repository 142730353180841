
main.block-desktop {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -42.2%);
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

main.block-desktop img {
  width: 100%;
  max-width: 400px;
}

