
/*
----------------------------------------------------------
RESETS
----------------------------------------------------------
*/

* {
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
----------------------------------------------------------
CSS VARS
----------------------------------------------------------
*/

:root {
  --primary-background: #000000;
  --primary-color: #000000;
  --btn-color: #000000;
  --btn-background: #ffffff;
  --btn-background-hover: #e1e1e1;
}

/*
----------------------------------------------------------
TYPE: FONT FACES
----------------------------------------------------------
*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

body, div,
a, blockquote, code, p,
button, input, select, option, textarea {
  font-family: arial, 'Helvetica', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p.smallCapsBold {
  font-family: 'cubano', 'Helvetica', verdana, arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
----------------------------------------------------------
TYPE: STYLES
----------------------------------------------------------
*/

body, button, p, a, a:visited, h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
}

h1 { text-align: center; font-size: 26px; line-height: 31px; margin-bottom: 22px; }
h2 { text-align: center; font-size: 22px; line-height: 27px; margin-bottom: 20px; }
h3 { text-align: center; font-size: 20px; line-height: 25px; margin-bottom: 20px; }
h4 { text-align: center; font-size: 18px; line-height: 23px; margin-bottom: 20px; }
h5 { text-align: center; font-size: 16px; line-height: 21px; margin-bottom: 20px; }
h6 { text-align: center; font-size: 14px; line-height: 19px; margin-bottom: 20px; }

h1 ,
h2 ,
h3 ,
h4 ,
h5 ,
h6 { 
  font-weight: 900;
}

p { 
  font-size: 16px; 
}

.bold, .strong { font-weight: bold; }
.em, .italic, .italics { font-style: italic; }
.nowrap { white-space: nowrap; }
.percentage { font-size: 4em; }

.text-small { font-size: 14px; }

/*
----------------------------------------------------------
TYPE: STYLES - RESPONSIVE
----------------------------------------------------------
*/

.text-left,
.text-left p { text-align: left; }

.text-center,
.text-center p { text-align: center; }

.text-right,
.text-right p { text-align: right; }

@media all and (width < 768px) {
.text-left-mobile,
.text-left-mobile p { text-align: left; }
.text-center-mobile,
.text-center-mobile p { text-align: center; }
.text-right-mobile,
.text-right-mobile p { text-align: right; }
}

@media all and (width >= 768px) and (width < 820px) {
.text-left-tablet,
.text-left-tablet p { text-align: left; }
.text-center-tablet,
.text-center-tablet p { text-align: center; }
.text-right-tablet,
.text-right-tablet p { text-align: right; }
}

@media all and (width >= 820px) and (width < 920px) {
.text-left-tablet-xl,
.text-left-tablet-xl p { text-align: left; }
.text-center-tablet-xl,
.text-center-tablet-xl p { text-align: center; }
.text-right-tablet-xl,
.text-right-tablet-xl p { text-align: right; }
}

@media all and (width >= 920px) {
.text-left-desktop,
.text-left-desktop p { text-align: left; }
.text-center-desktop,
.text-center-desktop p { text-align: center; }
.text-right-desktop,
.text-right-desktop p { text-align: right; }
}

/*
----------------------------------------------------------
BUTTONS
----------------------------------------------------------
*/

button {
  display: block;
  background: var(--btn-background);
  color: var(--btn-color);
  text-decoration: none;
}

button:hover {
  cursor: pointer;
  background: var(--btn-background-hover);
}

button,
button:active,
button:focus,
button:hover {
  text-decoration: none;
}

.btn {
  padding: 4px 16px 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  margin-top: 30px;
}

.btn-border-black {
  border: 3px solid #000000;
}

/*
----------------------------------------------------------
ACCESSIBILITY
----------------------------------------------------------
*/

.sr-only {
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px; 
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#ally-nav a {
  display: inline;
  margin-right: 10px;
}

/* a:focus,
button:focus {
  text-decoration: underline!important;
} */

/*
----------------------------------------------------------
CONTAINERS
----------------------------------------------------------
*/

body {
  background: var(--primary-background);
}

div {
  user-select: none;
}

#root {
  height:100vh;
}

/*
----------------------------------------------------------
LAYOUT
----------------------------------------------------------
*/

html,
body,
.no-scroll {
  overflow: hidden
}

.no-scroll-x {
  overflow-x: hidden
}

.no-scroll-y {
  overflow-y: hidden
}

@media all and (width <= 768px) {
  body.mobile-nav-expanded {
    height: 100vh;
    overflow: hidden;
  }
  body.mobile-nav-expanded .hero,
  body.mobile-nav-expanded main {
    display: none;
  }
}

main {
  width: 100%;
  max-width: 428px;
  margin: 0 auto;
}

.main__inner {
  margin: 40px 25px;
}

/*
----------------------------------------------------------
COLOURS
----------------------------------------------------------
*/

.bg-yellow {
  background-color: #fde700;
  color: #ffffff;
}

.bg-yellow h1,
.bg-yellow h2,
.bg-yellow h3,
.bg-yellow h4,
.bg-yellow h5,
.bg-yellow h6,
.bg-yellow p {
  color: #000000;
}

.bg-black button {
  background-color: #000000;
  color: #000000;
}

.bg-black {
  background-color: #000000;
  color: #ffffff;
}

.bg-black h1,
.bg-black h2,
.bg-black h3,
.bg-black h4,
.bg-black h5,
.bg-black h6,
.bg-black p {
  color: #ffffff;
}

.bg-black button {
  background-color: #ffde00;
  color: #000000;
}

/*
----------------------------------------------------------
HELPERS: RESPONSIVE
----------------------------------------------------------
*/

.show-mobile { display: block !important; }
.show-tablet { display: none !important; }
.show-desktop { display: none !important; }

.hide-mobile { display: none !important; }
.hide-tablet { display: initial !important; }
.hide-desktop { display: initial !important; }

@media all and (width >= 480px) {
  .show-tablet { display: initial !important; }
  .hide-mobile { display: none !important; }
}

@media all and (width >= 920px) {
  .show-desktop { display: initial !important; }
  .hide-tablet { display: none !important; }
}

/*
----------------------------------------------------------
HELPERS: CSS ANIMATIONS
----------------------------------------------------------
*/

.fade-in {
  animation: fade-in 1s;
}

@keyframes fade-in {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}

.fade-out {
  animation: fade-out 1s;
}

@keyframes fade-out {
	from {
		opacity:1;
	}
	to {
		opacity:0;
	}
}

/*
----------------------------------------------------------
CSS HELPERS
----------------------------------------------------------
*/

/* misc */

.new-line { line-height: 16px; }
.hidden { display: none; }

/* font weight */

.fw100 { font-weight: 100; }
.fw200 { font-weight: 200; }
.fw300 { font-weight: 300; }
.fw400 { font-weight: 400; }
.fw500 { font-weight: 500; }
.fw600 { font-weight: 600; }
.fw700 { font-weight: 700; }
.fw800 { font-weight: 800; }
.fw900 { font-weight: 900; }

/* margins */

.mt0 { margin-top: 0!important; }
.mt5 { margin-top: 5px!important; }
.mt10 { margin-top: 10px!important; }
.mt15 { margin-top: 15px!important; }
.mt20 { margin-top: 20px!important; }
.mt25 { margin-top: 25px!important; }
.mt30 { margin-top: 30px!important; }
.mt35 { margin-top: 35px!important; }
.mt40 { margin-top: 40px!important; }
.mt45 { margin-top: 45px!important; }
.mt50 { margin-top: 50px!important; }
.mt55 { margin-top: 55px!important; }
.mt60 { margin-top: 60px!important; }
.mt65 { margin-top: 65px!important; }
.mt70 { margin-top: 70px!important; }
.mt75 { margin-top: 75px!important; }

.mr0 { margin-right: 0!important; }
.mr5 { margin-right: 5px!important; }
.mr10 { margin-right: 10px!important; }
.mr15 { margin-right: 15px!important; }
.mr20 { margin-right: 20px!important; }
.mr25 { margin-right: 25px!important; }
.mr30 { margin-right: 30px!important; }
.mr35 { margin-right: 35px!important; }
.mr40 { margin-right: 40px!important; }
.mr45 { margin-right: 45px!important; }
.mr50 { margin-right: 50px!important; }
.mr55 { margin-right: 55px!important; }
.mr60 { margin-right: 60px!important; }
.mr65 { margin-right: 65px!important; }
.mr70 { margin-right: 70px!important; }
.mr75 { margin-right: 75px!important; }

.mb0 { margin-bottom: 0!important; }
.mb5 { margin-bottom: 5px!important; }
.mb10 { margin-bottom: 10px!important; }
.mb15 { margin-bottom: 15px!important; }
.mb20 { margin-bottom: 20px!important; }
.mb25 { margin-bottom: 25px!important; }
.mb30 { margin-bottom: 30px!important; }
.mb35 { margin-bottom: 35px!important; }
.mb40 { margin-bottom: 40px!important; }
.mb45 { margin-bottom: 45px!important; }
.mb50 { margin-bottom: 50px!important; }
.mb55 { margin-bottom: 55px!important; }
.mb60 { margin-bottom: 60px!important; }
.mb65 { margin-bottom: 65px!important; }
.mb70 { margin-bottom: 70px!important; }
.mb75 { margin-bottom: 75px!important; }

.ml0 { margin-left: 0!important; }
.ml5 { margin-left: 5px!important; }
.ml10 { margin-left: 10px!important; }
.ml15 { margin-left: 15px!important; }
.ml20 { margin-left: 20px!important; }
.ml25 { margin-left: 25px!important; }
.ml30 { margin-left: 30px!important; }
.ml35 { margin-left: 35px!important; }
.ml40 { margin-left: 40px!important; }
.ml45 { margin-left: 45px!important; }
.ml50 { margin-left: 50px!important; }
.ml55 { margin-left: 55px!important; }
.ml60 { margin-left: 60px!important; }
.ml65 { margin-left: 65px!important; }
.ml70 { margin-left: 70px!important; }
.ml75 { margin-left: 75px!important; }

/* padding */

.pt0 { padding-top: 0!important; }
.pt5 { padding-top: 5px!important; }
.pt10 { padding-top: 10px!important; }
.pt15 { padding-top: 15px!important; }
.pt20 { padding-top: 20px!important; }
.pt25 { padding-top: 25px!important; }
.pt30 { padding-top: 30px!important; }
.pt35 { padding-top: 35px!important; }
.pt40 { padding-top: 40px!important; }
.pt45 { padding-top: 45px!important; }
.pt50 { padding-top: 50px!important; }
.pt55 { padding-top: 55px!important; }
.pt60 { padding-top: 60px!important; }
.pt65 { padding-top: 65px!important; }
.pt70 { padding-top: 70px!important; }
.pt75 { padding-top: 75px!important; }

.pr0 { padding-right: 0!important; }
.pr5 { padding-right: 5px!important; }
.pr10 { padding-right: 10px!important; }
.pr15 { padding-right: 15px!important; }
.pr20 { padding-right: 20px!important; }
.pr25 { padding-right: 25px!important; }
.pr30 { padding-right: 30px!important; }
.pr35 { padding-right: 35px!important; }
.pr40 { padding-right: 40px!important; }
.pr45 { padding-right: 45px!important; }
.pr50 { padding-right: 50px!important; }
.pr55 { padding-right: 55px!important; }
.pr60 { padding-right: 60px!important; }
.pr65 { padding-right: 65px!important; }
.pr70 { padding-right: 70px!important; }
.pr75 { padding-right: 75px!important; }

.pb0 { padding-bottom: 0!important; }
.pb5 { padding-bottom: 5px!important; }
.pb10 { padding-bottom: 10px!important; }
.pb15 { padding-bottom: 15px!important; }
.pb20 { padding-bottom: 20px!important; }
.pb25 { padding-bottom: 25px!important; }
.pb30 { padding-bottom: 30px!important; }
.pb35 { padding-bottom: 35px!important; }
.pb40 { padding-bottom: 40px!important; }
.pb45 { padding-bottom: 45px!important; }
.pb50 { padding-bottom: 50px!important; }
.pb55 { padding-bottom: 55px!important; }
.pb60 { padding-bottom: 60px!important; }
.pb65 { padding-bottom: 65px!important; }
.pb70 { padding-bottom: 70px!important; }
.pb75 { padding-bottom: 75px!important; }

.pl0 { padding-left: 0!important; }
.pl5 { padding-left: 5px!important; }
.pl10 { padding-left: 10px!important; }
.pl15 { padding-left: 15px!important; }
.pl20 { padding-left: 20px!important; }
.pl25 { padding-left: 25px!important; }
.pl30 { padding-left: 30px!important; }
.pl35 { padding-left: 35px!important; }
.pl40 { padding-left: 40px!important; }
.pl45 { padding-left: 45px!important; }
.pl50 { padding-left: 50px!important; }
.pl55 { padding-left: 55px!important; }
.pl60 { padding-left: 60px!important; }
.pl65 { padding-left: 65px!important; }
.pl70 { padding-left: 70px!important; }
.pl75 { padding-left: 75px!important; }

/*
----------------------------------------------------------
RESPONSIVE PADDING
----------------------------------------------------------
*/

.pt-05vh { padding-top: .5vh; }
.pt-10vh { padding-top: 1vh; }
.pt-15vh { padding-top: 1.5vh; }
.pt-20vh { padding-top: 2vh; }
.pt-25vh { padding-top: 2.5vh; }
.pt-30vh { padding-top: 3vh; }
.pt-35vh { padding-top: 3.5vh; }
.pt-40vh { padding-top: 4vh; }
.pt-45vh { padding-top: 4.5vh; }
.pt-50vh { padding-top: 5vh; }

/*
----------------------------------------------------------
MODAL: DONATE
----------------------------------------------------------
*/

#bbox-root {
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: absolute;
  z-index: 5000;
  top: 0;
  left: 0;
  color: #000000;
  background: #ffffff;
}

body.overlay #bbox-root {
  display: block !important;
}

#bbox-root .BBFormSectionHeading {
  padding-top: 0;
}

#bbox-root h1, #bbox-root h2, #bbox-root p {
  color: #000000;
}

#bbox-root a.donate-close {
  position: absolute;
  top: 25px;
  right: 35px;
  font-family: 'cubano', 'Helvetica', verdana, arial, sans-serif;
  font-size: 20px;
  text-decoration: none;
}

#bbox-root img {
  width: 100%;
  max-width: 100%;
}

